import 'isomorphic-unfetch';

import React, { useEffect, useState } from 'react';
import App from 'next/app';
import * as Sentry from '@sentry/node';

import '../styles/index.css';
import AuthContext from '../contexts/auth';

// import { logEvent } from '../../utils/analytics';
// import * as Sentry from '@sentry/node';

const environment = process.env.isDev ? 'development' : process.env.isStaging ? 'staging' : 'production';
// Disable in local development
if (!process.env.isDev) {
  // Sentry is configured mostly following these two posts:
  // mostly this: https://spectrum.chat/next-js/general/config-nextjs-sourcemaps-in-sentry~630c66f8-69b4-41bf-8cad-98590b782322?m=MTU3NDQzNDM1MDA4NQ==
  // and a bit of this: https://leerob.io/blog/configuring-sentry-for-nextjs-apps/
  try {
    Sentry.init({
      dsn: `${process.env.SENTRY_DSN}`,
      environment,
    });
  } catch (error) {
    console.log(error);
  }
}

class MyApp extends App {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  // static async getInitialProps(appContext) {
  //   // calls page's `getInitialProps` and fills `appProps.pageProps`
  //   const appProps = await App.getInitialProps(appContext);
  //
  //   return { ...appProps }
  // }
  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    pageProps.err = ctx.err;
    return {
        pageProps,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log(`ERROR: ${ error }`);
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    // useEffect(() => {
      // logEvent('Data page loaded');
      // Sentry.captureException(new Error("This is my fake error message"));
    // });

    // // Workaround for https://github.com/zeit/next.js/issues/8592
    return <FnApp {...this.props} err={(this.props as any).err} />;
  }
}

const FnApp = ({ Component, pageProps, err }) => {
  const [authContext, setAuthContext] = useState({
    user: undefined,
    loading: true,
    authenticated: false,
    // setUser: user => setAuthContext({ ...authContext, user, loading: false, authenticated: user && !user.error }),
    // setLoading: loading => setAuthContext({ ...authContext, loading }),
  });

  // Following: https://auth0.com/blog/ultimate-guide-nextjs-authentication-auth0/#What-Does-Authentication-Mean-For-Next-js-
  useEffect(() => {
    async function getCurrentUser() {
      setAuthContext({ ...authContext, loading: true });
      try {
        const res = await fetch('/api/me');
        let authenticated = false;
        let user = undefined;
        if (res.ok) {
          const { user: userResponse } = await res.json();
          user = userResponse;
          authenticated = true;
        }
        setAuthContext({...authContext, user, loading: false, authenticated });
      } catch (error) {
        setAuthContext({...authContext, loading: false, authenticated: false });
      }
    }
    getCurrentUser();
  }, []);

  return (
    <AuthContext.Provider value={authContext}>
      <Component {...pageProps} err={err} authContext={authContext} />
    </AuthContext.Provider>
  )
}

export default MyApp;
